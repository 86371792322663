import React, { useState } from 'react';
import logo from '../assets/images/logo.svg';
import { Login, LOGIN } from '../constant';
import TbInput from '../components/common/ui/TbInput';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { postLogin } from '../api/accountAPI';
import useApiError from '../hooks/useApiError';

interface LoginResponse {
  grantType: string;
  accessToken: string;
  refreshToken: string;
}

interface LoginFormInputs {
  email: string;
  password: string;
}

const Logins: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const loginMutation = useMutation(postLogin);
  // State
  const tokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME || 'tidyb-admin-token';
  const [, setCookie] = useCookies([tokenName]); // Ensure your cookie name is defined here
  const navigate = useNavigate();
  const [isError, setIsError] = useState<boolean>(false);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  // Login Submit
  const onSubmit = async (data: LoginFormInputs) => {
    try {
      const resultData: LoginResponse = await loginMutation.mutateAsync(data);
      setCookie(tokenName, resultData.accessToken, { path: '/', expires: new Date(Date.now() + 86400000) }); // 24 hours expiration
      navigate(`/admin/workflow/list`);
    } catch (error) {
      setIsError(true);
      handleApiError(error);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="Tidy-B Logo" height={50} />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{LOGIN}</h4>
                          <h6>{'Enter your Username and Password'} </h6>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="theme-form">
                          <TbInput
                            label="이메일"
                            name={'email'}
                            id="email"
                            type="email"
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                          <TbInput
                            label="비밀번호"
                            name={'password'}
                            id="password"
                            type="password"
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="submit">
                              {Login}
                            </button>
                          </div>
                          {isError ? (
                            <div className={'p-20 mt-3 bg-danger '}>
                              <div>로그인에 실패하였습니다.</div>
                              <div>아이디 또는 비밀번호가 잘못 입력되었습니다.</div>
                            </div>
                          ) : null}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default Logins;
