import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { getPaymentList, ReqPaymentList } from '../../../api/paymentAPI';
import { paymentColumns } from '../../workflow/data/payment-columns';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import PayLinkModal from '../../payment/components/PayLinkModal';
import { useNavigate } from 'react-router-dom';

interface PropsType {
  idSubscription: number;
  status: string;
}

// 결제링크 생성기능 => 모달로 처리
// (구독 상세 페이지 공통으로 사용됨)
function ManagePayment({ idSubscription, status }: PropsType) {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const [dropdownOpen, setOpen] = useState(false);
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqPaymentList>({
    page: 0,
    size: 5,
    sort: '',
  });
  const [isPayLinkModal, setIsPayLinkModal] = useState(false);

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(
    ['getPaymentList', { reqParams }],
    () => getPaymentList({ ...reqParams, idSubscription }),
    {
      enabled: !!idSubscription,
      onSuccess: (data) => {
        setListData(data?._embedded?.payments || []);
        setTotalRows(data?.page?.totalElements | 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 아이템 클릭
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/payment/read/${row.id}`);
  };

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  const toggleModal = () => {
    setIsPayLinkModal(!isPayLinkModal);
  };
  return (
    <div>
      <PayLinkModal
        idSubscription={idSubscription}
        status={status}
        isOpen={isPayLinkModal}
        toggle={toggleModal}
      ></PayLinkModal>

      <div className="card">
        <div className={'card-header'}>
          <h5>결제 관리</h5>
          {idSubscription && (
            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle size={'sm'} color="dark" outline={true}>
                <span>
                  작업 <i className="fa fa-caret-down"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate(`/admin/payment/list?idSubscription=${idSubscription}`)}>
                  {'전체 내역 조회'}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  {'결제 링크 조회'}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {!idSubscription ? (
          <div className={'card-body'}>
            <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>
          </div>
        ) : (
          <div className={'card-body card-table-body'}>
            <DataTable
              noHeader
              columns={paymentColumns}
              data={listData}
              progressPending={isLoading}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(ManagePayment);
