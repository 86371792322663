import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../../../hooks/useApiError';
import TbInput from '../../../../components/common/ui/TbInput';
import TbModal from '../../../../components/common/ui/TbModal';
import TbLoading from '../../../../components/common/ui/TbLoading';
import Breadcrumb from '../../../../components/common/breadcrumb';
import TbRadio from '../../../../components/common/ui/TbRadio';
import TbTextarea from '../../../../components/common/ui/TbTextarea';
import {
  deleteTaskProductCategory,
  getTaskProductCategory,
  patchTaskProductCategory,
  postTaskProductCategory,
} from '../../../../api/taskAPI';
import { toast } from 'react-toastify';
import TbLangTab from '../../../../components/common/ui/TbLangTab';

interface CategoryFormData {
  textKoKr?: string;
  textEnUs?: string;
  textZhCn?: string;
  textZhTw?: string;
  textJaJp?: string;

  contentKoKr?: string;
  contentEnUs?: string;
  contentZhCn?: string;
  contentZhTw?: string;
  contentJaJp?: string;

  koKr?: string;
  enUs?: string;
  zhCn?: string;
  zhTw?: string;
  jaJp?: string;
  active?: string;
}

const options = [
  { label: '활성', value: 'true' },
  { label: '비활성', value: 'false' },
];

const UpdateTaskCategory: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const createCategoryIndustryMutation = useMutation(postTaskProductCategory);
  const deleteMutation = useMutation(deleteTaskProductCategory);
  const patchCategoryMutation = useMutation(({ id, categoryData }: { id: string; categoryData: any }) =>
    patchTaskProductCategory(id, categoryData),
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idBrand = queryParams.get('idBrand');
  const query = queryParams.get('query');
  const status = queryParams.get('status');
  //
  const { id: idCategory } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Date states
  // Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CategoryFormData>({
    defaultValues: {
      active: 'true', // 여기에 초기값 설정
      koKr: 'true',
      enUs: 'true',
      zhCn: 'true',
      zhTw: 'true',
      jaJp: 'true',
    },
  });

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(
    ['defaultData', idCategory],
    () => getTaskProductCategory(idCategory as string),
    {
      enabled: !!idCategory,
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);
      // title
      setValue('textKoKr', defaultData.name?.textKoKr);
      setValue('textEnUs', defaultData.name?.textEnUs);
      setValue('textZhCn', defaultData.name?.textZhCn);
      setValue('textZhTw', defaultData.name?.textZhTw);
      setValue('textJaJp', defaultData.name?.textJaJp);
      // content
      setValue('contentKoKr', defaultData.content?.textKoKr);
      setValue('contentEnUs', defaultData.content?.textEnUs);
      setValue('contentZhCn', defaultData.content?.textZhCn);
      setValue('contentZhTw', defaultData.content?.textZhTw);
      setValue('contentJaJp', defaultData.content?.textJaJp);
      // active
      setValue('koKr', String(defaultData.internationalResBody ? defaultData.internationalResBody?.koKr : true));
      setValue('enUs', String(defaultData.internationalResBody ? defaultData.internationalResBody?.enUs : false));
      setValue('zhCn', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhCn : false));
      setValue('zhTw', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhTw : false));
      setValue('jaJp', String(defaultData.internationalResBody ? defaultData.internationalResBody?.jaJp : false));
      setValue('active', String(defaultData.active));
    }
  }, [defaultData]);

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    console.debug('data:::::', data);

    const requestData = {
      name: {
        textKoKr: data.textKoKr,
        textEnUs: data.textEnUs,
        textZhCn: data.textZhCn,
        textZhTw: data.textZhTw,
        textJaJp: data.textJaJp,
      },
      content: {
        textKoKr: data.contentKoKr,
        textEnUs: data.contentEnUs,
        textZhCn: data.contentZhCn,
        textZhTw: data.contentZhTw,
        textJaJp: data.contentJaJp,
      },
      internationalReqBody: {
        koKr: data.koKr === 'true',
        enUs: data.enUs === 'true',
        zhCn: data.zhCn === 'true',
        zhTw: data.zhTw === 'true',
        jaJp: data.jaJp === 'true',
      },
      active: data.active === 'true',
    };

    try {
      if (idCategory && Number(idCategory) > 0) {
        // Update (PATCH)
        await patchCategoryMutation.mutateAsync({
          id: String(idCategory),
          categoryData: requestData,
        });
        navigate(`/admin/task/product/category/list`);
      } else {
        // Create (POST)
        await createCategoryIndustryMutation.mutateAsync(requestData);
        navigate(`/admin/task/product/category/list`);
      }

      toast.success('작업이 성공적으로 완료되었습니다.');
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    if (idCategory) {
      try {
        await deleteMutation.mutateAsync(idCategory);
        navigate(`/admin/task/product/category/list`);
        toast.success('삭제 완료');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  const handleRowClicked = () => {
    const params = new URLSearchParams();

    if (idBrand) params.set('idBrand', idBrand);
    if (query) params.set('query', query);
    if (status) params.set('status', status);

    navigate(`/admin/task/product/category/list?${params.toString()}`);
  };
  //  =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="카테고리" title={idCategory ? '수정' : '생성'}>
          <div className="mb-0">
            {idCategory && (
              <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : idCategory ? '수정' : '생성'}
            </Button>

            <Button color="primary" onClick={handleRowClicked}>
              목록
            </Button>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="8">
              <TbLangTab title={'작업 카테고리'} errors={errors}>
                <div>
                  <TbInput
                    label="제목"
                    name={'textKoKr'}
                    type="text"
                    placeholder="제목"
                    register={register}
                    errors={errors}
                  />
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'contentKoKr'}
                    placeholder="설명"
                    rows={10}
                    register={register}
                    errors={errors}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="koKr" register={register} />
                </div>
                <div>
                  <TbInput
                    label="제목"
                    placeholder="제목"
                    name={'textEnUs'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'contentEnUs'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="enUs" register={register} />
                </div>
                <div>
                  <TbInput label="제목" name={'textZhCn'} type="text" register={register} errors={errors} />
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'contentZhCn'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="zhCn" register={register} />
                </div>
                <div>
                  <TbInput
                    label="제목"
                    placeholder="제목"
                    name={'textZhTw'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'contentZhTw'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="zhTw" register={register} />
                </div>
                <div>
                  <TbInput
                    label="제목"
                    placeholder="제목"
                    name={'textJaJp'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'contentJaJp'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="jaJp" register={register} />
                </div>
              </TbLangTab>
            </Col>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>설정</h5>
                </div>
                <CardBody>
                  <TbRadio title={'게시 상태'} options={options} name="active" register={register} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default UpdateTaskCategory;
