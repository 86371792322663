import React, { Fragment, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbRadio from '../../components/common/ui/TbRadio';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { patchSetting } from '../../api/settingAPI';
import { fetchSetting } from '../../store/reducers/settingSlice';
import { toast } from 'react-toastify';

interface KeywordFormData {
  basicLang?: string;
  isInternational?: string;
  enUs?: string;
  zhCn?: string;
  zhTw?: string;
  jaJp?: string;
}

const options = [
  { label: '활성', value: 'true' },
  { label: '비활성', value: 'false' },
];

// const langOption = [
//   { label: '한국어', value: 'ko_KR' },
//   { label: '영어', value: 'en_US' },
//   { label: '중국어(간체)', value: 'zh_CN' },
//   { label: '중국어(번체)', value: 'zh_TW' },
//   { label: '일본어', value: 'ja_JP' },
// ];

const SettingPage: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const updateSettingMutation = useMutation(patchSetting);
  const dispatch: AppDispatch = useDispatch();
  const defaultData = useSelector((state: RootState) => state.setting.data);

  // Date states
  // Form
  const { register, handleSubmit, setValue, watch } = useForm<KeywordFormData>({
    defaultValues: {
      enUs: 'false',
      zhCn: 'false',
      zhTw: 'false',
      jaJp: 'false',
    },
  });
  // 다국어 모드 값 감시
  const isInternational = watch('isInternational');

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);

      // 클라이언트 기본 값 설정
      setValue('isInternational', String(defaultData.international)); // 국제화 활성화 여부
      // setValue('koKr', String(defaultData.internationalMode.koKr)); // 한국어 여부
      setValue('enUs', String(defaultData.internationalMode.enUs)); // 영어 여부
      setValue('zhCn', String(defaultData.internationalMode.zhCn)); // 중국어(간체) 여부
      setValue('zhTw', String(defaultData.internationalMode.zhTw)); // 중국어(번체) 여부
      setValue('jaJp', String(defaultData.internationalMode.jaJp)); // 일본어 여부
    }
  }, [defaultData]);

  // 최종  Form Submit
  const onSubmit = async (data: any) => {
    console.debug('onSubmit Data:::::', data);
    const requestData = {
      international: data.isInternational,
      internationalReqBody: {
        enUs: isInternational === 'true' ? data.enUs === 'true' : false,
        zhCn: isInternational === 'true' ? data.zhCn === 'true' : false,
        zhTw: isInternational === 'true' ? data.zhTw === 'true' : false,
        jaJp: isInternational === 'true' ? data.jaJp === 'true' : false,
      },
    };

    // Create (POST)
    console.debug('Create Data:::', requestData);
    try {
      await updateSettingMutation.mutateAsync(requestData);
      dispatch(fetchSetting());
      toast.success('변경 완료');
    } catch (error) {
      handleApiError(error);
    }
  };

  //  =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="설정" title="언어 설정">
          <div className="mb-0">
            <Button color="primary" className="me-3" type="submit">
              저장
            </Button>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>언어 설정</h5>
                </div>
                <CardBody>
                  {/* 다국어 모드 */}
                  <TbRadio title={'다국어 모드'} options={options} name="isInternational" register={register} />
                  {isInternational === 'true' && (
                    <div className={'mt-3'}>
                      <div style={{ margin: '0 -30px', borderBottom: '1px solid #e8ebf2' }}></div>
                      {/*<TbRadio title={'국문'} options={options} name="koKr" register={register} />*/}
                      <div className={'mt-3'}>
                        <TbRadio title={'영문'} options={options} name="enUs" register={register} />
                      </div>
                      <div className={'mt-2'}>
                        <TbRadio title={'중문 (간체)'} options={options} name="zhCn" register={register} />
                      </div>
                      <div className={'mt-2'}>
                        <TbRadio title={'중문 (번체)'} options={options} name="zhTw" register={register} />
                      </div>
                      <div className={'mt-2'}>
                        <TbRadio title={'일문'} options={options} name="jaJp" register={register} />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default SettingPage;
