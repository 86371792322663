import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Col, Container, Row } from 'reactstrap';
import { getPayment } from '../../api/paymentAPI';
import moment from 'moment/moment';
import ManageBrand from '../subscription/components/ManageBrand';

const ReadPayment: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const { id: idPayment } = useParams<{ id: string }>();

  // Update 일떄 초기 데이터 로드
  const { data: defaultData } = useQuery(['defaultData', idPayment], () => getPayment(idPayment as string), {
    onError: (error) => {
      handleApiError(error);
    },
  });

  // =================== 공통 항목 끝 ==================  //

  // R ea d
  return (
    <Fragment>
      <Breadcrumb parent="결제 내역 관리" title="상세 정보">
        <div className="mb-0">
          <Link to={`/admin/payment/list`}>
            <Button color="primary">목록</Button>
          </Link>
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col lg={4}>
            {/****** 브랜드/유저 정보 ******/}
            <ManageBrand idBrand={defaultData?.subscription?.idBrand} />
          </Col>

          <Col lg={8}>
            {/* ::::::::::::: 결제 내역 ::::::::::: */}
            <div className="card">
              <div className={'card-header'}>
                <h5>결제 내역</h5>
              </div>
              <div className={'card-body'}>
                <Row className="details">
                  <Col xs="6" className={'detail-item'}>
                    PID(결제번호)
                    <br />
                    <span>{defaultData?.pid}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    구독 ID
                    <br />
                    <span>{defaultData?.subscription?.id}</span>
                  </Col>
                </Row>
                <hr />
                <Row className="details">
                  <Col xs="6" className={'detail-item'}>
                    결제 내용
                    <br />
                    <span>{defaultData?.title}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제 금액
                    <br />
                    <span>{defaultData?.amount.toLocaleString()}원</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제일
                    <br />
                    <span>
                      {defaultData?.paymentDate
                        ? `${moment(defaultData?.paymentDate).format('YYYY/MM/DD HH:MM:SS')}`
                        : '-'}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제 상태
                    <br />
                    <div>
                      {defaultData?.orderStatus === 'INIT' && <span className={'btn btn-xs btn-info'}>결제 대기</span>}
                      {defaultData?.orderStatus === 'PAID' && (
                        <span className={'btn btn-xs btn-success'}>결제 완료</span>
                      )}
                      {defaultData?.orderStatus === 'FAILED' && (
                        <span className={'btn btn-xs btn-danger'}>결제 실패</span>
                      )}
                      {defaultData?.orderStatus === 'REFUND' && (
                        <span className={'btn btn-xs btn-warning'}>환불 완료</span>
                      )}
                    </div>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제 방법
                    <br />
                    <div>
                      {defaultData?.type === 'CARD' && (
                        <span className={'btn btn-xs btn-default'}>
                          신용카드({defaultData?.brand} ****-{defaultData?.lastNumber})
                        </span>
                      )}
                      {defaultData?.type === 'CASH' && <span className={'btn btn-xs btn-default'}>현금</span>}
                      {defaultData?.type === 'CREDIT' && <span className={'btn btn-xs btn-default'}>크레딧</span>}
                      {defaultData?.type === 'COUPON' && <span className={'btn btn-xs btn-default'}>쿠폰</span>}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* ::::::::::::: 세부 내역 상세 ::::::::::: */}
            <div className="card">
              <div className={'card-header'}>
                <h5>세부 내역 상세</h5>
              </div>
              <div className={'card-body'}>
                {!defaultData?.target && <p className={`text-center m-5-15 text-danger`}>세부 내역이 없습니다.</p>}
                {defaultData?.target === 'SUBSCRIBE' && (
                  <>
                    <Row className="details">
                      <Col xs="6" className={'detail-item'}>
                        납입 주기
                        <br />
                        <span>
                          {defaultData?.periodStartDate
                            ? moment(defaultData?.periodStartDate).format('YYYY/MM/DD')
                            : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.periodEndDate ? moment(defaultData?.periodEndDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        약정 기간(계약 기간)
                        <br />
                        <span>
                          {defaultData?.startDate ? moment(defaultData?.startDate).format('YYYY/MM/DD') : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.endDate ? moment(defaultData?.endDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        결제 주기
                        <br />
                        <span>{defaultData?.periodType}</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        멤버 추가 사용량
                        <br />
                        <span>{defaultData?.memberUsage} 계정</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        멤버 계정당 요금
                        <br />
                        <span>{defaultData?.priceOfMember.toLocaleString()} 원/계정</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        총 할인율
                        <br />
                        <span>{defaultData?.discountRate * 100}%</span>
                      </Col>
                    </Row>
                    <hr />
                  </>
                )}
                {(defaultData?.target === 'SUBSCRIBE' || defaultData?.target === 'CREDIT') && (
                  <>
                    <Row className="details">
                      <Col xs="6" className={'detail-item'}>
                        크레딧 지급
                        <br />
                        <span>{defaultData?.creditAmount.toLocaleString()} 크레딧</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        크레딧 할인율
                        <br />
                        <span>{defaultData?.creditDiscountRate * 100}%</span>
                      </Col>
                    </Row>
                  </>
                )}
                {defaultData?.target === 'LOGO' && (
                  <>
                    <Row className="details">
                      <Col xs="6" className={'detail-item'}>
                        납입 주기
                        <br />
                        <span>
                          {defaultData?.periodStartDate
                            ? moment(defaultData?.periodStartDate).format('YYYY/MM/DD')
                            : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.periodEndDate ? moment(defaultData?.periodEndDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        약정 기간(계약 기간)
                        <br />
                        <span>
                          {defaultData?.startDate ? moment(defaultData?.startDate).format('YYYY/MM/DD') : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.endDate ? moment(defaultData?.endDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        결제 주기
                        <br />
                        <span>{defaultData?.periodType}</span>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </Fragment>
  );
};

export default ReadPayment;
