import React, { Fragment, useState } from 'react';
import TbFilter from '../../components/common/ui/TbFilter';
import DataTable from 'react-data-table-component';
import useApiError from '../../hooks/useApiError';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import { KeywordColumns } from './data/keyword-columns';
import { getKeywordList } from '../../api/keywordAPI';
import { formatEndDate, formatStartDate } from '../../utils/formatUtils';
import { PageInfo } from '../../api/types';

const KeywordList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  //  state
  const [reqParam, setReqParam] = useState<PageInfo>({
    query: '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 10,
    sort: '',
  });

  const { isLoading } = useQuery(
    ['getKeywordPage', { reqParam }], // queryKey에 reqParam을 개별 요소로 전달
    () => getKeywordList(reqParam), // queryFn에 필요한 인수들을 각각 전달
    {
      onSuccess: (data) => {
        setPageInfoData(data._embedded.adminBrandKeywordResBodies || []);
        setTotalRows(data?.page?.totalElements || 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // 페이지 변경 핸들러
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  // 한 페이지 당 보여줄 행 수 변경 핸들러
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  // =================== 공통 항목 끝 ==================  //

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      query: filters.query,
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/keyword/update/${row.id}`);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-10">
            <Breadcrumb parent="키워드 관리" title="목록">
              <Link className="btn btn-primary" to={`/admin/keyword/update`}>
                새로 등록
              </Link>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {/* :::::::::: Filter : Start ::::::::::  */}
          <div className="col-xl-10">
            <TbFilter onSubmit={onSubmitSearch} />
          </div>
          {/* :::::::::: Filter : End ::::::::::  */}

          {/*  :::::::::: List ::::::::::  */}
          <div className=" col-xl-10 mb-4">
            <DataTable
              noHeader
              columns={KeywordColumns}
              data={pageInfoData}
              progressPending={isLoading}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KeywordList;
