import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbInput from '../../components/common/ui/TbInput';
import TbSelect from '../../components/common/ui/TbSelect';
import DataTable from 'react-data-table-component';
import { getPaymentList, ReqPaymentList } from '../../api/paymentAPI';
import { paymentColumns } from './data/payment-columns';

import { formatEndDate, formatStartDate } from '../../utils/formatUtils';
const orderStatusList: any[] = [
  { label: '결제 대기', value: 'INIT' },
  { label: '결제 완료', value: 'PAID' },
  { label: '결제 실패', value: 'FAILED' },
  { label: '환불 완료', value: 'REFUND' },
];

function PaymentList() {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  // Query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idSubscription = queryParams.get('idSubscription');
  const query = queryParams.get('query');
  const pid = queryParams.get('pid');
  const orderStatus = queryParams.get('orderStatus');

  // Pagination
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqPaymentList>({
    query: query || '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 10,
    sort: '',
    orderStatus: orderStatus ? (orderStatus as 'INIT' | 'PAID' | 'FAILED' | 'REFUND') : undefined,
    pid: pid ? (pid as string) : undefined,
    idSubscription: idSubscription ? Number(idSubscription) : undefined,
  });

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(
    ['getPaymentList', { reqParams }],
    () => getPaymentList({ ...reqParams, idSubscription: Number(idSubscription) || undefined }),
    {
      onSuccess: (data) => {
        setListData(data?._embedded?.payments || []);
        setTotalRows(data?.page?.totalElements | 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      query: filters.query || '',
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
      idSubscription: filters.idSubscription || undefined,
      orderStatus: filters.orderStatus || undefined,
      pid: filters.pid || undefined,
    });
    // Construct URL query
    const searchParams = new URLSearchParams();

    if (filters.idSubscription) searchParams.set('idSubscription', filters.idSubscription.toString());
    if (filters.query) searchParams.set('query', filters.query);
    if (filters.orderStatus) searchParams.set('orderStatus', filters.orderStatus);
    if (filters.pid) searchParams.set('pid', filters.pid);

    //
    navigate({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    const params = new URLSearchParams();

    if (idSubscription) params.set('idSubscription', idSubscription.toString());
    if (reqParams.query) params.set('query', reqParams.query);
    if (reqParams.orderStatus) params.set('orderStatus', reqParams.orderStatus);
    if (reqParams.pid) params.set('pid', reqParams.pid);

    navigate(`/admin/payment/read/${row.id}?${params.toString()}`);
  };

  return (
    <div>
      <Breadcrumb parent="결제 내역" title="목록"></Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch}>
              {({ register, errors, setValue }) => {
                useEffect(() => {
                  setValue('query', query);
                  setValue('idSubscription', idSubscription);
                  setValue('orderStatus', orderStatus);
                  setValue('pid', pid);
                }, [idSubscription]);
                return (
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">구독 ID</label>
                        </Col>
                        <Col sm={10}>
                          <TbInput
                            name={'idSubscription'}
                            placeholder={'구독 ID'}
                            type="text"
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">결제 타입</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            label="결제 타입"
                            row
                            placeholder={'전체'}
                            name="orderStatus"
                            options={orderStatusList ? orderStatusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">결제 번호</label>
                        </Col>
                        <Col sm={10}>
                          <TbInput
                            name={'pid'}
                            placeholder={'결제 번호'}
                            type="text"
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          <div className={'col-xl-12'}>
            <div className={'card-body card-table-body'}>
              <DataTable
                noHeader
                columns={paymentColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PaymentList);
