import React, { Fragment, useEffect, useState } from 'react';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import { deleteContact, getContact, updateContact, UpdateReqContact } from '../../api/contactAPI';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import TbModal from '../../components/common/ui/TbModal';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import TbLoading from '../../components/common/ui/TbLoading';
import Breadcrumb from '../../components/common/breadcrumb';
import TbSelect from '../../components/common/ui/TbSelect';
import TbTextarea from '../../components/common/ui/TbTextarea';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

interface ContactData {
  id?: string;
  company?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  status?: 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CONVERSION';
  memo?: string;
}

const statusList: any[] = [
  { label: '신규 문의', value: 'REQUEST' },
  { label: '상담중', value: 'PROCEEDING' },
  { label: '상담 완료', value: 'COMPLETED' },
  { label: '고객 전환', value: 'CONVERSION' },
];

const UpdateContact: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchContactMutation = useMutation(({ id, data }: { id: string; data: any }) => updateContact(id, data));
  const deleteMutation = useMutation(deleteContact);
  const navigate = useNavigate();
  const { id: id } = useParams<{ id: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ContactData>();

  const { data: defaultData } = useQuery(['defaultData', id], () => getContact(id as string), {
    enabled: !!id,
    onError: (error) => {
      handleApiError(error);
    },
  });

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);
      setValue('company', defaultData.company);
      setValue('name', defaultData.name);
      setValue('email', defaultData.email);
      setValue('phoneNumber', defaultData.phoneNumber);
      setValue('status', defaultData.status);
      setValue('memo', defaultData.memo);
    }
  }, [defaultData]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const reqBody = {
      ...data,
      company: data.company ? data.company : null,
      name: data.name ? data.name : null,
      email: data.email ? data.email : null,
      phoneNumber: data.phoneNumber ? data.phoneNumber : null,
      status: data.status ? data.status : null,
      memo: data.memo ? data.memo : null,
    } as UpdateReqContact;
    if (id && Number(id) > 0) {
      try {
        await patchContactMutation.mutateAsync({
          id: String(id),
          data: { ...reqBody },
        });
        navigate(`/admin/contact/update/${id}`);
        toast.success(`수정되었습니다.`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        });
        setIsLoading(false);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleDelete = async () => {
    if (id) {
      try {
        await deleteMutation.mutateAsync(id);
        navigate(`/admin/contact/list`);
        toggleModal();
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="문의" title="수정">
          <div className="mb-0">
            {id ? (
              <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            ) : (
              ''
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : '수정'}
            </Button>
            <Link to={`/admin/contact/list`}>
              <Button color="primary">목록</Button>
            </Link>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row className="details">
            <Col sm="6">
              <Card>
                <div className="card-header">
                  <h5>신청자 정보</h5>
                </div>
                <CardBody>
                  <Col sm="12" className={'detail-item'}>
                    기업명 / 브랜드명
                    <br />
                    <span>{defaultData?.company}</span>
                  </Col>
                  <Col sm="12" className={'detail-item'}>
                    담당자 성함
                    <br />
                    <span>{defaultData?.name}</span>
                  </Col>
                  <Col sm="12" className={'detail-item'}>
                    이메일
                    <br />
                    <span>{defaultData?.email}</span>
                  </Col>
                  <Col sm="12" className={'detail-item'}>
                    핸드폰 번호
                    <br />
                    <span>{defaultData?.phoneNumber}</span>
                  </Col>
                  <Col sm="12" className={'detail-item'}>
                    문의 날짜
                    <br />
                    <span>{moment(defaultData?.createdDate).format('YYYY-MM-DD HH:MM:SS')}</span>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card>
                <div className="card-header">
                  <h5>문의 정보</h5>
                </div>
                <CardBody>
                  <TbSelect
                    label="상태"
                    isPlaceholder={false}
                    name="status"
                    options={statusList ? statusList : []}
                    register={register}
                    errors={errors}
                  />
                  <TbTextarea
                    label="메모"
                    id="memo"
                    name={'memo'}
                    placeholder="메모를 작성해 주세요."
                    rows={10}
                    register={register}
                    errors={errors}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};

export default UpdateContact;
