import UseApiError from '../../hooks/useApiError';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getContactPage, ReqContactList } from '../../api/contactAPI';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTable from 'react-data-table-component';
import { contactColumns } from './data/contact-columns';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/TbSelect';
import { formatEndDate, formatStartDate } from '../../utils/formatUtils';

const statusList: any[] = [
  { label: '신규 문의', value: 'REQUEST' },
  { label: '상담중', value: 'PROCEEDING' },
  { label: '상담 완료', value: 'COMPLETED' },
  { label: '고객 전환', value: 'CONVERSION' },
];

function ContactList() {
  const handleApiError = UseApiError();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');
  const status = queryParams.get('status');

  // Pagination
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqContactList>({
    query: query || '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 10,
    sort: '',
    status: status ? (status as 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CONVERSION') : undefined,
  });

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(['getContactPage', { reqParams }], () => getContactPage({ ...reqParams }), {
    onSuccess: (data) => {
      setListData(data?._embedded?.contactResBodies || []);
      setTotalRows(data?.page?.totalElements | 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      query: filters.query || '',
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
      status: filters.status || undefined,
    });
    // Construct URL query
    const searchParams = new URLSearchParams();

    if (filters.query) searchParams.set('query', filters.query);
    if (filters.status) searchParams.set('status', filters.status);

    //
    navigate({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    const params = new URLSearchParams();
    if (reqParams.query) params.set('query', reqParams.query);
    if (reqParams.status) params.set('status', reqParams.status);

    navigate(`/admin/contact/update/${row.id}?${params.toString()}`);
  };

  return (
    <div>
      <Breadcrumb parent="구독" title="목록"></Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch}>
              {({ register, errors, setValue }) => {
                useEffect(() => {
                  setValue('query', query);
                  setValue('status', status);
                });
                return (
                  <Row>
                    <Col sm={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">처리 상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            label="구독 상태"
                            row
                            placeholder={'전체'}
                            name="status"
                            options={statusList ? statusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          <div className={'col-xl-12'}>
            <div className={'card-body card-table-body'}>
              <DataTable
                noHeader
                columns={contactColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ContactList);
