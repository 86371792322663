import React, { Fragment, useState } from 'react';
import TbFilter from '../../components/common/ui/TbFilter';
import DataTable from 'react-data-table-component';
import useApiError from '../../hooks/useApiError';
import { getWorkflowEnum, getWorkflowPage, ReqWorkflowList } from '../../api/workflowAPI';
import { useQuery } from 'react-query';
import { workflowColumns } from './data/workflow-columns';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/TbSelect';
import { getPlanList, ResPlan } from '../../api/planAPI';
import { formatEndDate, formatStartDate } from '../../utils/formatUtils';

const CardView = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  // state
  const [reqParam, setReqParam] = useState<ReqWorkflowList>({
    query: '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 10,
    sort: '',
    // custom
    onBoarding: '',
    design: '',
    platform: '',
    manager: '',
    priority: '',
  });

  const { isLoading } = useQuery(['getWorkflowPage', { reqParam }], () => getWorkflowPage(reqParam), {
    onSuccess: (data) => {
      setPageInfoData(data?._embedded?.workflowPageResBodies || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  // 페이지 변경 핸들러
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  // 한 페이지 당 보여줄 행 수 변경 핸들러
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  // Update 이동
  // const handleRowClick = (row: any) => {
  //   navigate(`${process.env.PUBLIC_URL}/workflow/update/${row.id}`);
  // };

  // =================== 공통 항목 끝 ==================  //

  // 요금제 목록 로드
  const { data: planList } = useQuery(['getPlanList'], () => getPlanList({ isActive: undefined }), {
    select: (data: ResPlan[]) =>
      data.map((planInfo) => ({
        label: planInfo.title + ` (ID.${planInfo.id}, Lv.${planInfo.level})`,
        value: Number(planInfo.id),
      })),
    onError: (error) => {
      handleApiError(error);
    },
  });

  // Fetch Enum Data ::: Select 데이터 가져오기
  const formatList = (list: Record<string, string>) => {
    return Object.entries(list).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  };

  // Enum API
  const { data: enumType } = useQuery(['enumData'], getWorkflowEnum, {
    // enabled: !!platformList,
    select: (data) => ({
      onBoardingList: formatList(data.onBoardingList),
      designList: formatList(data.designList),
      platformList: formatList(data.platformList),
      managerList: formatList(data.managerList),
      priorityList: formatList(data.priorityList),
    }),
    onError: (error) => {
      handleApiError(error);
    },
  });

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      query: filters.query,
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
      onBoarding: filters.onBoarding,
      design: filters.design,
      platform: filters.platform,
      manager: filters.manager,
      priority: filters.priority,
      idPlan: filters.idPlan,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/workflow/read/${row.id}`);
  };

  return (
    <Fragment>
      <Breadcrumb parent="고객 관리" title="목록">
        <Link className="btn btn-primary" to={`/admin/workflow/update`}>
          새로 등록
        </Link>
      </Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          {/* :::::::::: Filter : Start ::::::::::  */}
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch}>
              {({ register, errors }) => (
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col sm={2}>
                        <label className="col-form-label">온보딩 상태</label>
                      </Col>
                      <Col sm={10}>
                        <TbSelect
                          label="온보딩 상태"
                          row
                          placeholder={'전체'}
                          name="onBoarding"
                          options={enumType?.onBoardingList ? enumType.onBoardingList : []}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col sm={2}>
                        <label className="col-form-label">디자인 상태</label>
                      </Col>
                      <Col sm={10}>
                        <TbSelect
                          label="디자인 상태"
                          row
                          placeholder={'전체'}
                          name="design"
                          options={enumType?.designList ? enumType.designList : []}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col sm={2}>
                        <label className="col-form-label">플랫폼</label>
                      </Col>
                      <Col sm={10}>
                        <TbSelect
                          label="플랫폼"
                          row
                          placeholder={'전체'}
                          name="platform"
                          options={enumType?.platformList ? enumType.platformList : []}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col sm={2}>
                        <label className="col-form-label">매니저</label>
                      </Col>
                      <Col sm={10}>
                        <TbSelect
                          row
                          placeholder={'전체'}
                          label="매니저"
                          name="manager"
                          options={enumType?.managerList ? enumType.managerList : []}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col sm={2}>
                        <label className="col-form-label">우선순위</label>
                      </Col>
                      <Col sm={10}>
                        <TbSelect
                          row
                          placeholder={'전체'}
                          label="우선순위"
                          name="priority"
                          options={enumType?.priorityList ? enumType.priorityList : []}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col sm={2}>
                        <label className="col-form-label">요금제</label>
                      </Col>
                      <Col sm={10}>
                        <TbSelect
                          row
                          placeholder={'전체'}
                          label="요금제"
                          name="idPlan"
                          options={planList ? planList : []}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </TbFilter>
          </div>
          {/* :::::::::: Filter : End ::::::::::  */}

          {/*  :::::::::: List ::::::::::  */}
          <div className=" col-xl-12 mb-4">
            <DataTable
              noHeader
              columns={workflowColumns}
              data={pageInfoData}
              progressPending={isLoading}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardView;
