// src/routes/private-route.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import useCheckMe from '../hooks/useCheckMe';
import AppLayout from '../components/app';
import { useCookies } from 'react-cookie';
import { getMe } from '../api/accountAPI';
import TbLoading from '../components/common/ui/TbLoading';

const tokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME || 'tidyb-admin-token';

const PrivateRoute = () => {
  // 로그인 체크
  const [cookies] = useCookies([tokenName]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    // 토큰 유효 체크
    const checkAuthStatus = async () => {
      // 토큰 체크
      const token = cookies[tokenName];
      // 토큰 O
      if (token) {
        try {
          // me 체크
          const response = await getMe();
          console.debug('getMe_private-route:::', response);
          // me success
          if (response.success) {
            setIsLogin(response.success);
            // dispatch(updateIsUserData(response.success));
          }
          // me fail
        } catch (error) {
          console.debug('Error fetching auth status:', error);
        }
      } else {
        // 토큰 X
        <Navigate to={`/admin/login`} />;
      }
      setIsLoading(false);
    };
    checkAuthStatus();
  }, [cookies, dispatch]);

  // 로딩
  if (isLoading) {
    return <TbLoading fullPage={true} />;
  }

  // 로그인 체크
  // Outlet => Children과 비슷한 기능을 한다.
  // https://reactrouter.com/en/main/components/outlet
  return isLogin ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to={`/admin/login`} />
  );
};

export default PrivateRoute;
