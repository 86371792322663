import React, { Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../../components/common/breadcrumb';
import { getTaskProductCategoryPage } from '../../../../api/taskAPI';
import useApiError from '../../../../hooks/useApiError';
import { CategoryTaskColumns } from '../../data/task-product-columns';
import TbFilter from '../../../../components/common/ui/TbFilter';

const ProductCategoryList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  //  state
  const [reqParam, setReqParam] = useState({
    query: '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 10,
    sort: '',
  });

  const { isLoading } = useQuery(
    ['getTaskProductCategoryPage', { reqParam }],
    () => getTaskProductCategoryPage(reqParam),
    {
      onSuccess: (data) => {
        setPageInfoData(data._embedded.categories || []);
        setTotalRows(data?.page?.totalElements || 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // 페이지 변경 핸들러
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  // 한 페이지 당 보여줄 행 수 변경 핸들러
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  // =================== 공통 항목 끝 ==================  //

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      query: filters.query,
      startDate: filters.startDate ? filters.startDate.toISOString() : null,
      endDate: filters.endDate ? filters.endDate.toISOString() : null,
    });
  };

  //  행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/task/product/category/update/${row.id}`);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <Breadcrumb parent="작업 카테고리" title="목록">
              <Link className="btn btn-primary" to={`/admin/task/product/category/update`}>
                새로 등록
              </Link>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {/* :::::::::: Filter : Start ::::::::::  */}
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch} />
          </div>
          {/* :::::::::: Filter : End ::::::::::  */}
          {/*  :::::::::: List ::::::::::  */}
          <div className="col-xl-12 mb-4">
            <DataTable
              noHeader
              columns={CategoryTaskColumns}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              data={pageInfoData}
              progressPending={isLoading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductCategoryList;
