import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import useApiError from '../../../hooks/useApiError';
import { getBrandInfo } from '../../../api/brandAPI';
import { useNavigate } from 'react-router-dom';
import { ReqCredentials, ResJwtToken, userLogin } from '../../../api/accountAPI';
import { toast } from 'react-toastify';

interface PropsType {
  idBrand?: string; // idBrand의 타입을 명확하게 설정 (string)
  refetchBrandInfo?: boolean;
}

const ManageBrand: React.FC<PropsType> = ({ idBrand, refetchBrandInfo }) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [dropdownOpen, setOpen] = useState(false);
  const loginUserMutation = useMutation(userLogin);

  // 초기 데이터 로드
  const { data: defaultData, refetch } = useQuery(['defaultData', idBrand], () => getBrandInfo(idBrand as string), {
    enabled: !!idBrand,
    onError: (error) => {
      handleApiError(error);
    },
  });

  useEffect(() => {
    if (idBrand) {
      refetch();
    }
  }, [refetchBrandInfo]);

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  /**
   * 계정 접속
   * */
  const loginUserAccount = useCallback(async () => {
    if (defaultData.leave) {
      toast.warn('탈퇴한 계정입니다.');
      return;
    }
    const reqBody: ReqCredentials = { email: defaultData?.email };
    const resData: ResJwtToken = await loginUserMutation.mutateAsync(reqBody);
    if (window) {
      document.cookie = `tidyb-access-token=${resData.accessToken}; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
      window.open(`${process.env.REACT_APP_SERVICE_URL}/brand`, '_blank');
    }
  }, [defaultData]);

  return (
    <Fragment>
      <Card>
        <div className="card-header">
          <h5>브랜드 정보</h5>
          {idBrand && (
            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle size={'sm'} color="dark" outline={true}>
                <span>
                  작업 <i className="fa fa-caret-down"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate(`/admin/subscription/read/${defaultData.idSubscription}`)}>
                  {'구독 상세 내역'}
                </DropdownItem>
                <DropdownItem onClick={() => loginUserAccount()}>{'고객 계정 접속'}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {!idBrand ? (
          <p className={'text-center m-t-15 text-danger'}>브랜드를 연결하세요.</p>
        ) : (
          <CardBody>
            <Row>
              <Col xs="6" className={'detail-item'}>
                브랜드 ID
                <br />
                <span>{defaultData?.id}</span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                브랜드명
                <br />
                <span>{defaultData?.name ? defaultData.name : '-'}</span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                소유 계정 ID
                <br />
                <span>{defaultData?.idUser}</span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                소유 계정 이메일
                <br />
                <span>
                  {defaultData?.leave ? <span className={'text-danger'}>탈퇴 계정</span> : defaultData?.email}
                </span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                기업명
                <br />
                <span>{defaultData?.businessName ? defaultData?.businessName : '-'}</span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                대표자
                <br />
                <span>{defaultData?.representative ? defaultData?.representative : '-'}</span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                보유 크레딧
                <br />
                <span>{defaultData?.credit ? defaultData?.credit : '0'} Credit</span>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </Fragment>
  );
};
export default ManageBrand;
