import React from 'react';
import moment from 'moment';

export const contactColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    center: true,
    minWidth: '50px',
    cell: (row: any) => <div className={'text-left'}>ID.{row.id}</div>,
  },
  {
    name: '기업명/브랜드명',
    selector: (row: any) => row.company,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '담당자 성함',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '이메일',
    selector: (row: any) => row.email,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '전화번호',
    selector: (row: any) => row.phoneNumber,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '처리 상태',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
    cell: (row: any) => (
      <div>
        {row.status === 'REQUEST' && <span className={'btn btn-xs btn-info'}>신규 문의</span>}
        {row.status === 'PROCEEDING' && <span className={'btn btn-xs btn-warning'}>상담중</span>}
        {row.status === 'COMPLETED' && <span className={'btn btn-xs btn-danger'}>상담 완료</span>}
        {row.status === 'CONVERSION' && <span className={'btn btn-xs btn-success'}>고객 전환</span>}
      </div>
    ),
  },
  {
    name: '문의일',
    selector: (row: any) => moment(row.createdDate).format('YYYY/MM/DD HH:MM:SS'),
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
];
