import APIClient from './APIClient';
import { PageInfo } from './types';
import { ResPlan } from './planAPI';

// Get
export interface ReqWorkflowList extends PageInfo {
  onBoarding?: string;
  design?: string;
  platform?: string;
  manager?: string;
  priority?: string;
  idPlan?: number;
  // idBrand?: string | undefined;
}

// Create Update
export interface ReqWorkflow {
  id?: number;
  brandName?: string;
  businessName?: string;
  userName?: string;
  phoneNumber?: string;
  memo?: string;
  manager?: string;
  priority?: string;
  price?: string;
  onBoarding?: string | undefined;
  design?: string;
  platform?: string;
  startDate?: string;
  endDate?: string;
  idBrand?: number;
  idPlan?: number;
}
export interface ResWorkflow {
  brandName?: string;
  businessName?: string;
  userName?: string;
  phoneNumber?: string;
  memo?: string;
  manager?: string;
  priority?: string;
  price?: string;
  onBoarding?: string;
  design?: string;
  platform?: string;
  startDate?: string;
  endDate?: string;
  plan?: ResPlan;
}

// WorkFlow Page Info
export const getWorkflowPage = async (params: ReqWorkflowList) => {
  const response = await APIClient.get('/v2/api/workflow/page', {
    params,
  });
  return response.data;
};

// Create WorkFlow
export const createWorkflow = async (workflow: ReqWorkflow) => {
  const response = await APIClient.post('/v2/api/workflow', workflow);
  return response.data;
};

// Update WorkFlow
export const patchWorkflow = async (id: string, workflow: ReqWorkflow) => {
  const response = await APIClient.patch(`/v2/api/workflow/${id}`, workflow);
  return response.data;
};

// Get WorkFlow Enum
export const getWorkflowEnum = async () => {
  const response = await APIClient.get('/v2/api/workflow/enum');
  return response.data;
};

// Get WorkFlow Detail
export const getWorkflowDetail = async (id: string) => {
  const response = await APIClient.get(`/v2/api/workflow/${id}`);
  return response.data;
};

// Delete
export const deleteWorkflow = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/workflow/${id}`);
  return response.data;
};

// 브랜드 연결
export const patchWorkflowConnect = async (ids: { idWorkflow: number; idBrand: number }) => {
  const response = await APIClient.patch('/v2/api/workflow/connect-brand', ids);
  return response.data;
};

export const getLogoQuestDetail = async (idBrand: number) => {
  const response = await APIClient.get(`/v2/api/quest/logo?idBrand=${idBrand}`);
  return response.data;
};
