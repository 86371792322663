// 메뉴
import React from 'react';
import moment from 'moment';

//
export const workflowColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    // sortable: true,
    center: true,
    // wrap: true,
    width: '60px',
  },
  {
    name: '브랜드명/기업명',
    selector: (row: any) => row.brandName,
    cell: (row: any) => (
      <div>
        {row.brandName} / {row.businessName}
      </div>
    ),
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '플랫폼',
    selector: (row: any) => row.platform,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '우선순위',
    selector: (row: any) => row.priority,
    sortable: true,
    center: true,
    wrap: true,
    // width: '80px',
  },
  {
    name: '매니저',
    selector: (row: any) => row.manager,
    sortable: true,
    center: true,
    wrap: true,
    // width: '70px',
  },
  {
    name: '온보딩 상태',
    selector: (row: any) => row.onBoarding,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '디자인 상태',
    selector: (row: any) => row.design,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '담당자',
    // selector: (row: any) => row.userName, // 정렬 기준
    cell: (row: any) => (
      <div style={{ whiteSpace: 'nowrap' }}>
        {row.userName}
        <br />
        {row.phoneNumber}
      </div>
    ),
    sortable: true,
    center: true,
    wrap: true,
    width: '90px',
  },
  {
    name: '일자',
    // selector: (row: any) => moment(row.startDate).format('YYYY/MM/DD'),
    cell: (row: any) => (
      <div style={{ whiteSpace: 'nowrap' }}>
        시작일: {row.startDate ? moment(row.startDate).format('YYYY/MM/DD') : '-'}
        <br />
        종료일: {row.endDate ? moment(row.endDate).format('YYYY/MM/DD') : '-'}
      </div>
    ),
    sortable: true,
    center: true,
    wrap: true,
    width: '124px',
  },
  {
    name: '요금제',
    selector: (row: any) => row.planName,
    sortable: true,
    center: true,
    wrap: true,
    // format: (row: any) => `${row.price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  },
];
