import { PageInfo } from './types';
import APIClient from './APIClient';

export interface ReqSubscriptionPage extends PageInfo {
  type?: 'FREE' | 'PAID' | 'ADMIN';
  status?: 'SUBSCRIBE' | 'EXPIRED';
  periodType?: 'MONTHLY' | 'YEARLY';
  idBrand?: number;
}

export interface ReqCreatePayLink {
  idPlan?: string;
  idCreditPlan?: string;
  idSubscription?: string;
  expiredDate?: Date | null | undefined;
  periodType?: string;
}

/** 구독 **/
// 구독 페이징
export const getSubscriptionPage = async (params: ReqSubscriptionPage) => {
  const response = await APIClient.get(`/v2/api/subscription/page`, {
    params,
  });
  return response.data;
};

// 구독 상세
export const getSubscription = async (id: string) => {
  const response = await APIClient.get(`/v2/api/subscription/${id}`);
  return response.data;
};

// 결제링크 조회
export const getPayLink = async (idSubscription: string) => {
  const response = await APIClient.get(`/v2/api/subscription/payLink`, {
    params: { idSubscription },
  });
  return response.data;
};

export const deletePayLink = async (idPayLink: string) => {
  await APIClient.delete(`/v2/api/subscription/payLink/${idPayLink}`);
};

export const createPayLink = async (payLink: ReqCreatePayLink) => {
  await APIClient.post(`/v2/api/subscription/payLink`, payLink);
};
