import { AlignRight, Bell, CreditCard, Search, Settings, StopCircle, Truck, Users } from 'react-feather';

export const MENUITEMS = [
  // {
  //   title: 'Dashboard',
  //   icon: Home,
  //   type: 'sub',
  //   badgeType: 'primary',
  //   active: false,
  //   children: [
  //     { path: `/dashboard`, title: 'Default', type: 'link' },
  //     { path: `/workflow`, title: 'WorkFlow', type: 'link' },
  //   ],
  // },
  {
    title: '고객 관리',
    type: 'label',
  },
  {
    title: '고객 관리',
    icon: Users,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/workflow/list`,
    // children: [{ path: `/workflow`, title: 'WorkFlow', type: 'link' }],
  },
  {
    title: '회원 구독 관리',
    icon: Bell,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/subscription/list`,
  },
  {
    title: '결제 내역',
    icon: CreditCard,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/payment/list`,
  },
  {
    title: '크레딧 내역',
    icon: StopCircle,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/credit/list`,
    // children: [{ path: `/workflow`, title: 'WorkFlow', type: 'link' }],
  },
  {
    title: '디자이너 작업 관리',
    icon: AlignRight,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/task/list`,
    // children: [{ path: `/workflow`, title: 'WorkFlow', type: 'link' }],
    children: [
      { path: `/admin/task/list`, title: '신청 작업 관리', type: 'link' },
      { path: `/admin/task/product/category/list`, title: '작업 카테고리', type: 'link' },
      { path: `/admin/task/product/list`, title: '작업 상품 ', type: 'link' },
    ],
  },
  {
    title: '문의 내역 관리',
    icon: StopCircle,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/contact/list`,
    // children: [{ path: `/workflow`, title: 'WorkFlow', type: 'link' }],
  },
  {
    title: '리소스 관리',
    type: 'label',
  },
  {
    title: '산업군 관리',
    icon: Truck,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/industry/category/list`,
    children: [
      { path: `/admin/industry/category/list`, title: '카테고리', type: 'link' },
      { path: `/admin/industry/list`, title: '산업군', type: 'link' },
    ],
  },
  {
    title: '키워드 관리',
    icon: Search,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/keyword/list`,
  },
  {
    title: '시스템 설정',
    type: 'label',
  },
  {
    title: '언어 설정',
    icon: Settings,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/setting`,
  },
];
